@use "../../sass/global/colors" as *;

.Add-Campaign-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 72px;
  padding-bottom: 80px;
  gap: var(--3, 24px);

  @media screen and (max-width: 500px) {
    padding: 0 26px;
  }

  @media screen and (max-width: 390px) {
    padding: 0 16px;
  }
  .campaign-paganation {
    display: flex;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.06));
  }

  .campaign-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    .campaign-card {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 16px);
      align-self: stretch;
      border-radius: 8px;
      background: var(--background-default, #fff);
      .trigger-controls {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        gap: 36px;
        align-self: stretch;
      }
      .activity-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row !important;
        align-items: center;
        align-self: stretch;

        .activity-info{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }
        .activity-btn{
          display: flex;
          align-items: flex-start;
          gap: 24px;
        }
      }
      .activites{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        width: 100%;
        .each-activity{
          display: flex;
          height: 82px;
          padding: var(--2, 16px) var(--3, 24px) var(--2, 16px) var(--2, 16px);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: var(--borderRadius, 8px);
          background: var(--surface-secondary, #F6F8F8);
          .icons-group{
            display: flex;
            height: 36px;
            padding: 0px 4px;
            align-items: flex-start;
            gap: 4px;
          }
        }
      }
    }
  }
}
