.review-email-edit-screen-container {
  width: 100%;
  padding: 0 4.5rem;

  .sms-edit-pagination {
    display: flex;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--none, 0px);
    align-self: stretch;
    border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.06));
    .back-button {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    }
    .toggler {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--7, 56px);
      align-self: stretch;
      .toggler-buttons {
        display: flex;
        padding: 6px;
        align-items: center;
        gap: 8px;
        border-radius: 300px;
        border: 1px solid var(--primary-light, #338484);
      }
      .toggler-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }
    }
  }
  .sms-container,
  .email-container {
    display: flex;
    align-items: flex-start;
    gap: var(--8, 64px);
    align-self: stretch;
    .inner-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--3, 24px);
      flex: 1 0 0;
      .controls {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--1, 8px);
        align-self: stretch;
      }
      .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        .alret-dailog {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
        .buttons {
          display: flex;
          align-items: flex-start;
          gap: 12px;
        }
      }
    }
    .preview-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .phone-frame {
        position: relative;
        width: 235.669px;
        height: 477px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .outer {
          width: 235px;
          height: 6px;
          flex-shrink: 0;
        }

        .phone-content {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }

        .phoneImg {
          position: absolute;
          width: 235.669px;
          height: 476.851px;
          flex-shrink: 0;
        }

        .sms-message {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          color: black;
          border-radius: 16px 16px 16px 16px;
          width: 210px;
          min-height: 450px;
          max-height: 450px;
          overflow-y: auto;
          padding: 16px 16px;
          font-size: 0.75rem;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          gap: 4px;
          line-height: 1.2;
          white-space: normal;
          .sms-content {
            display: flex;
            flex-direction: column;
            max-width: 160px;
            margin-left: 1rem;
            align-items: flex-end;
            padding: var(--1, 8px) var(--2, 16px);
            justify-content: center;
            border-radius: var(--borderRadius-large, 16px)
              var(--borderRadius-large, 16px) 0px
              var(--borderRadius-large, 16px);
            background: var(--blueGrey-A700, #455a64);
          }
          .phone-frame-content {
            display: flex;
            margin-top: 3.5rem;
            flex-direction: column;
            justify-content: space-between;

            .title {
              text-align: center;
            }

            .content {
              color: rgba(0, 0, 0, 0.87);
              font-size: 12px;
              font-family: "IBM Plex Sans";
              font-weight: 400;
              letter-spacing: 0.17;
              word-wrap: "break-word";
            }

            .greetings {
              padding: 20px 0;
            }
            .submit-btn {
              margin-top: 16px;
            }
          }
        }

        .message-text {
          overflow-wrap: break-word;
          word-break: break-word;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0 1.5rem;
  }
}
