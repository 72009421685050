@use "../../sass/global/colors" as *;


.dashboard-container {
  max-width: 1440px;
  padding: 24px 5rem;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;

    h1 {
      font-size: 1.5rem;
    }

    span {
      color: #888;
    }
  }

  .metrics-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;

    button {
      margin-left: 0.5rem;
    }
  }

  .reviews-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .reviews-info {
      display: flex;
      align-items: center;
      gap: 1rem;
      

      .reviews-logo {
        width: 36px;
        height: 36px;
        background-color: #f6f5f5;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
      }
    }
  }

  .dashboard-tabs {
    border-bottom: 1px solid #e0e0e0;
    margin-top: 1rem;
    flex-grow: 1;
    .tab {
      font-size: 14px;
      font-weight: var(--fontWeightMedium, 500);
      text-transform: capitalize;
    }
  }

  .tab-content {
    margin-top: 48px;
  }

  .review-info-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 1rem !important;
    width: 100%;
    height: 108px;
    padding: 3px 20px 3px 14px;
    border-radius: 8px;
    background: var(--surface-secondary, #F6F8F8) !important;

    @media screen and (max-width: 450px) {
      padding: 1rem 10px;
      height: auto;
    }

    .card-title {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .toggleButtonGroup {
      display: flex;
      align-items: flex-start;
      border-radius: var(--borderRadius-small, 4px);
      font-size: 13px;

      .toggleButton {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        border-right: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
        background: var(--action-hover, rgba(0, 0, 0, 0.04));
        .toggle-text{
          text-align: center;
          
          /* button/small */
          font-family: var(--fontFamily, "IBM Plex Sans");
          font-size: var(--font-size-08125-rem, 13px);
          font-style: normal;
          font-weight: var(--fontWeightMedium, 500);
          line-height: 22px; /* 169.231% */
          letter-spacing: 0.46px;
        }
      }
    }

  

    @media screen and (max-width: 880px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 880px) {
    padding: 24px 1rem;
  }
}
