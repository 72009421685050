@use "../../sass/global/colors" as *;

.new-profile-container {
  display: flex;
  margin-top: 48px;
  padding: 0px var(--9, 72px);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  .inner-container {
    display: flex;
    max-width: 1440px;
    padding-bottom: 80px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
    align-self: stretch;

    .profile-pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--3, 24px);
      align-self: stretch;
      .profile-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .tabs-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
        .tab {
          text-transform: none;
        }
      }
    }

    .website-container,
    .business-container,
    .category-container,
    .description-container,
    .place-container,
    .offers-container {
      display: flex;
      height: 134px;
      max-width: 768px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 16px);
      align-self: stretch;
      .inner-container {
        display: flex;
        padding: 18px var(--3, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--2, 16px);
        align-self: stretch;
        border-radius: var(--borderRadius, 8px);
        background: var(--primary-contrastText, #fff);
        .text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          align-self: stretch;
          .text-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
          }
        }
      }
    }
    .key-people-container,
    .competitors-container,
    .location-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .main-header {
        display: flex;
        padding: 16px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.06));
        background: var(--primary-main, #0f4343);
        .main-header-inner {
          display: flex;
          width: 1008px;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          .main-header-inner-cell {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
          }
        }
      }
      .second-header {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.06));
        background: var(--surface-tertiary, #e2e6e9);
        .row {
          display: flex;
          padding: 6px 16px;
          align-items: center;
          flex: 1 0 0;
          background: var(--_library-clickableLayer, rgba(0, 0, 0, 0));
          .header-text {
            font-family: var(--fontFamily, "IBM Plex Sans");
            font-size: var(--font-size-0875-rem, 14px);
            font-style: normal;
            font-weight: var(--fontWeightMedium, 500);
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.17px;
          }
        }
      }
      .body-row {
        display: flex;
        height: 62px;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
        .row-cell {
          display: flex;
          padding: 16px;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          background: var(--_library-clickableLayer, rgba(0, 0, 0, 0));
          .row-inner-cell {
            display: flex;
            padding: 6px 16px;
            align-items: center;
            align-content: center;
            gap: 1px 2px;
            flex: 1 0 0;
            flex-wrap: wrap;
            border-radius: 300px;
            background: var(--text-_states-hover, rgba(0, 0, 0, 0.04));
          }
        }
      }
      .footer-button {
        display: flex;
        padding: 10px 0px;
        align-items: center;
        gap: 24px;
        align-self: stretch;
      }
    }
  }
}
