@use '../../sass/global/colors' as *;

.products-table-container {
    width: 1056px;
    overflow-x: auto;
    .table-container{
      max-width: 100%;
      
    }

    .table-header{
      background-color: rgb(226,230,233);
      border-bottom: 2px solid #ccc;
      padding: none !important;
    }

    .css-1goqruv-MuiTableCell-root{
      padding: 6px 16px !important;
    }
   

    .link{
      color: $sidebar-bg-color;
      cursor: pointer;
      text-decoration: underline;
    }
  
    .add-product {
      color: #00695f;
      cursor: pointer;
      font-weight: 500;
    }
  
    .table-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
  
      .csv-links {
        a {
          margin-left: 16px;
          text-decoration: none;
          color: $sidebar-bg-color;
          cursor: pointer;
        }
      }

      @media screen and (max-width: 338px) {
        flex-direction: column;
        justify-content: center;
        
      }
    }
  
    .delete-icon {
      color: $sidebar-bg-color;
      cursor: pointer;
    }
  }
  