.stepper-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner-stepper-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .stepper-radio-group {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .stepper-item {
    position: relative;
    padding-left: 10px;
  
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: 30px;
      top: 30px;
      width: 2px;
      height: 25px;
      background-color: #ccc;
    }
  
    &.Mui-checked {
      color: #1976d2;
    }
  }
  
  .Mui-checked + .MuiFormControlLabel-label {
    font-weight: bold;
  }
  