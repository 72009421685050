.fourthtab-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--borderRadius, 8px);
  background: var(--background-paper-elevation-1, #fff);
  /* elevation/1 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  .card-header {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    flex: 1 0 0;
  }

  .card-controls {
    display: flex;
    padding: 0px 16px 24px 16px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    .inner-controls {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}
