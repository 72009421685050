.startegy-container {
  display: flex;
  padding: 0px var(--9, 72px);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  .container {
    display: flex;
    width: 1056px;
    max-width: 1440px;
    padding-bottom: 80px;
    flex-direction: column;
    align-items: flex-start;
    background: var(--surface-secondary, #f6f8f8) ;
    gap: var(--3, 24px);
    .title-container {
      display: flex;
      padding: 32px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--3, 24px);
      align-self: stretch;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
      }
    }
    .tabs-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      text-transform: none !important;

      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;

        .tab-label {
          display: flex;
          padding: 9px 16px;
          justify-content: center;
          align-items: flex-end;
          gap: 8px;

          .tab-label-text {
            color: var(--primary-main, #0f4343);
            font-family: var(--fontFamily, "IBM Plex Sans");
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-transform: none;
            letter-spacing: 0.16px;
          }
        }
      }
    }
    .first-tab {
      display: flex;
      align-items: center;
      gap: 24px;
      .upload-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    .card-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: var(--borderRadius, 8px);
      background: var(--background-paper-elevation-1, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
      .card-header {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 17px;
        align-self: stretch;
        .card-header-inner {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
      
    }
    .table-footer {
      display: flex;
      padding: 10px 0px;
      align-items: center;
      gap: 24px;
      align-self: stretch;
    }
    .footer-icons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
    }
    .secondTab,.thirdTab, .fourthTab, .fifthTab{
      display: flex;
      align-items: center;
      gap: 24px;
      .controls{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
    }
  }
}


