
.Custom-table-wrapper3{
    border-radius: var(--borderRadius, 8px);
    background: var(--surface-secondary, #F6F8F8) !important;
    .MuiTableRow-root {
        border-bottom: none;

    }

    .MuiTableCell-root {
        border-bottom: none;
        padding: 0; 
    }         
}

.Custom-table-inner-wrapper3{
    background: none !important;
    border: none !important;
}


.tableCellHeader {
    padding: 0;
    font-size: 12px;
    max-width: 46px;
  }
  

.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px; 
    min-width: 220px;
    padding: 0;
    text-align: left;
}

